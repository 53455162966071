import React, { useReducer, createContext } from "react";
import { getJSON, putJSON } from "utils/axios";

const initialState = {
  list: {
    isLoading: false,
    data: null,
    error: null,
  },
  detail: {
    isLoading: false,
    isUpdating: false,
    data: null,
    error: null,
  },
};

export const ResidentsStateContext = createContext();
export const ResidentsDispatchContext = createContext();

const ACTION_TYPES = {
  GET_RESIDENTS_REQUEST: "GET_RESIDENTS_REQUEST",
  GET_RESIDENTS_SUCCESS: "GET_RESIDENTS_SUCCESS",
  GET_RESIDENTS_FAILURE: "GET_RESIDENTS_FAILURE",
  CREATE_RESIDENT_REQUEST: "CREATE_RESIDENT_REQUEST",
  CREATE_RESIDENT_SUCCESS: "CREATE_RESIDENT_SUCCESS",
  CREATE_RESIDENT_FAILURE: "CREATE_RESIDENT_FAILURE",
  UPDATE_RESIDENT_REQUEST: "UPDATE_RESIDENT_REQUEST",
  UPDATE_RESIDENT_SUCCESS: "UPDATE_RESIDENT_SUCCESS",
  UPDATE_RESIDENT_FAILURE: "UPDATE_RESIDENT_FAILURE",
  GET_RESIDENT_DETAIL_REQUEST: "GET_RESIDENT_DETAIL_REQUEST",
  GET_RESIDENT_DETAIL_SUCCESS: "GET_RESIDENT_DETAIL_SUCCESS",
  GET_RESIDENT_DETAIL_FAILURE: "GET_RESIDENT_DETAIL_FAILURE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_RESIDENTS_REQUEST:
      return {
        ...state,
        list: {
          data: null,
          error: null,
          isLoading: true,
        },
      };
    case ACTION_TYPES.GET_RESIDENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          isLoading: false,
          error: null,
        },
      };
    case ACTION_TYPES.GET_RESIDENTS_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          isLoading: false,
          error: action.payload.error,
        },
      };
    case ACTION_TYPES.UPDATE_RESIDENT_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isEditing: true,
        },
      };
    case ACTION_TYPES.UPDATE_RESIDENT_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isEditing: false,
        },
      };
    case ACTION_TYPES.UPDATE_RESIDENT_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          isEditing: false,
        },
      };
    case ACTION_TYPES.GET_RESIDENT_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
        },
      };
    case ACTION_TYPES.GET_RESIDENT_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          data: action.payload.data,
        },
      };
    case ACTION_TYPES.GET_RESIDENT_DETAIL_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: null,
          error: action.payload.error,
          isLoading: false,
        },
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const ResidentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ResidentsDispatchContext.Provider value={dispatch}>
      <ResidentsStateContext.Provider value={state}>
        {children}
      </ResidentsStateContext.Provider>
    </ResidentsDispatchContext.Provider>
  );
};

export const getAllResidents = async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_RESIDENTS_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/flat`
    );
    dispatch({
      type: ACTION_TYPES.GET_RESIDENTS_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.GET_RESIDENTS_FAILURE,
      payload: {
        error: error || "Sorry, unable to fetch residents!",
      },
    });
  }
};

export const getAllUsers = async () => {
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/user/getAllResidents`
    );
    if (response) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error(error);
  }
};

export const getResidentDetail = async (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_RESIDENT_DETAIL_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/flat/${id}`
    );
    dispatch({
      type: ACTION_TYPES.GET_RESIDENT_DETAIL_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    console.log("==> error", error);
    dispatch({
      type: ACTION_TYPES.GET_RESIDENT_DETAIL_FAILURE,
      payload: {
        error: error || "Oops, something went wrong!",
      },
    });
  }
};

export const updateResident = async (dispatch, id, values) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_RESIDENT_REQUEST,
  });
  try {
    const response = await putJSON(
      `${process.env.REACT_APP_API_BASE_URL}/flat/${id}`,
      values
    );
    if (response) {
      dispatch({
        type: ACTION_TYPES.UPDATE_RESIDENT_SUCCESS,
      });
      return getResidentDetail(dispatch, id);
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.UPDATE_RESIDENT_FAILURE,
    });
  }
};

export default ResidentsProvider;
