import React, { useReducer, createContext } from "react";
import { getJSON, putJSON } from "utils/axios";

const initialState = {
  list: {
    isLoading: false,
    total: 0,
    data: null,
    error: null,
  },
  detail: {
    isLoading: false,
    isUpdating: false,
    data: null,
    error: null,
    isCreatingPaymentURL: false,
    paymentRequest: null,
    isConfirmPayByCash: false,
    isConfirmPayByDirectTransfer: false,
  },
};

export const PaymentsStateContext = createContext();
export const PaymentsDispatchContext = createContext();

const ACTION_TYPES = {
  GET_PAYMENTS_REQUEST: "GET_PAYMENTS_REQUEST",
  GET_PAYMENTS_SUCCESS: "GET_PAYMENTS_SUCCESS",
  GET_PAYMENTS_FAILURE: "GET_PAYMENTS_FAILURE",
  CREATE_PAYMENT_URL_REQUEST: "CREATE_PAYMENT_URL_REQUEST",
  CREATE_PAYMENT_URL_SUCCESS: "CREATE_PAYMENT_URL_SUCCESS",
  CREATE_PAYMENT_URL_FAILURE: "CREATE_PAYMENT_URL_FAILURE",
  UPDATE_PAYMENT_REQUEST: "UPDATE_PAYMENT_REQUEST",
  UPDATE_PAYMENT_SUCCESS: "UPDATE_PAYMENT_SUCCESS",
  UPDATE_PAYMENT_FAILURE: "UPDATE_PAYMENT_FAILURE",
  GET_PAYMENT_DETAIL_REQUEST: "GET_PAYMENT_DETAIL_REQUEST",
  GET_PAYMENT_DETAIL_SUCCESS: "GET_PAYMENT_DETAIL_SUCCESS",
  GET_PAYMENT_DETAIL_FAILURE: "GET_PAYMENT_DETAIL_FAILURE",
  SET_CONFIRM_PAY_BY_CASH: "SET_CONFIRM_PAY_BY_CASH",
  SET_CONFIRM_PAY_BY_DIRECT_TRANSFER: "SET_CONFIRM_PAY_BY_DIRECT_TRANSFER",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_PAYMENTS_REQUEST:
      return {
        ...state,
        list: {
          data: null,
          error: null,
          isLoading: true,
        },
      };
    case ACTION_TYPES.GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          total: action.payload.total,
          isLoading: false,
          error: null,
        },
      };
    case ACTION_TYPES.GET_PAYMENTS_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          isLoading: false,
          error: action.payload.error,
        },
      };
    case ACTION_TYPES.CREATE_PAYMENT_URL_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          paymentRequest: null,
          isCreatingPaymentURL: true,
        },
      };
    case ACTION_TYPES.CREATE_PAYMENT_URL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          paymentRequest: action.payload.paymentRequest,
          isCreatingPaymentURL: false,
        },
      };
    case ACTION_TYPES.CREATE_PAYMENT_URL_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          paymentRequest: null,
          isCreatingPaymentURL: false,
        },
      };
    case ACTION_TYPES.GET_PAYMENT_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          error: null,
        },
      };
    case ACTION_TYPES.GET_PAYMENT_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case ACTION_TYPES.GET_PAYMENT_DETAIL_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: null,
          error: action.payload.error,
          isLoading: false,
        },
      };
    case ACTION_TYPES.SET_CONFIRM_PAY_BY_CASH:
      return {
        ...state,
        detail: {
          ...state.detail,
          isConfirmPayByCash: !state.detail.isConfirmPayByCash,
        },
      };
    case ACTION_TYPES.SET_CONFIRM_PAY_BY_DIRECT_TRANSFER:
      return {
        ...state,
        detail: {
          ...state.detail,
          isConfirmPayByDirectTransfer:
            !state.detail.isConfirmPayByDirectTransfer,
        },
      };
    case ACTION_TYPES.UPDATE_PAYMENT_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isUpdating: true,
        },
      };
    case ACTION_TYPES.UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isUpdating: false,
          isConfirmPayByCash: false,
          isConfirmPayByDirectTransfer: false,
        },
      };
    case ACTION_TYPES.UPDATE_PAYMENT_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          isUpdating: false,
          isConfirmPayByCash: false,
          isConfirmPayByDirectTransfer: false,
        },
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const PaymentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PaymentsDispatchContext.Provider value={dispatch}>
      <PaymentsStateContext.Provider value={state}>
        {children}
      </PaymentsStateContext.Provider>
    </PaymentsDispatchContext.Provider>
  );
};

export const getAllPayments = async (dispatch, status = "ALL", page = 1) => {
  dispatch({
    type: ACTION_TYPES.GET_PAYMENTS_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/payment?page=${page}&status=${status}`
    );
    dispatch({
      type: ACTION_TYPES.GET_PAYMENTS_SUCCESS,
      payload: {
        data: response.data.data,
        total: response.data.total,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.GET_PAYMENTS_FAILURE,
      payload: {
        error: error || "Sorry, unable to fetch your payments!",
      },
    });
  }
};

export const createPayment = (dispatch, payment) => {
  console.log(payment);
};

export const createPaymentRequest = async (dispatch, id, payment) => {
  dispatch({
    type: ACTION_TYPES.CREATE_PAYMENT_URL_REQUEST,
  });
  try {
    const response = await putJSON(
      `${process.env.REACT_APP_API_BASE_URL}/payment/${id}/payment-request`,
      payment
    );
    dispatch({
      type: ACTION_TYPES.CREATE_PAYMENT_URL_SUCCESS,
      payload: {
        paymentRequest: response.data,
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.CREATE_PAYMENT_URL_FAILURE,
    });
  }
};

export const updateOfflinePayment = async (dispatch, id, payment) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_PAYMENT_REQUEST,
  });
  try {
    const response = await putJSON(
      `${process.env.REACT_APP_API_BASE_URL}/payment/${id}/offline-payment`,
      payment
    );
    dispatch({
      type: ACTION_TYPES.UPDATE_PAYMENT_SUCCESS,
      payload: {
        paymentRequest: response.data,
      },
    });
    return getPaymentDetail(dispatch, id);
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.UPDATE_PAYMENT_FAILURE,
    });
  }
};

export const updatePaymentDetails = async (dispatch, id, payment) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_PAYMENT_REQUEST,
  });
  try {
    const response = await putJSON(
      `${process.env.REACT_APP_API_BASE_URL}/payment/${id}/offline-payment`,
      payment
    );
    dispatch({
      type: ACTION_TYPES.UPDATE_PAYMENT_SUCCESS,
      payload: {
        paymentRequest: response.data,
      },
    });
    return getPaymentDetail(dispatch, id);
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.UPDATE_PAYMENT_FAILURE,
    });
  }
};

export const updatePaymentInfo = async (dispatch, id, payment) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_PAYMENT_REQUEST,
  });
  try {
    const response = await putJSON(
      `${process.env.REACT_APP_API_BASE_URL}/payment/${id}/update-payment`,
      payment
    );
    dispatch({
      type: ACTION_TYPES.UPDATE_PAYMENT_SUCCESS,
      payload: {
        paymentRequest: response.data,
      },
    });
    return getPaymentDetail(dispatch, id);
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.UPDATE_PAYMENT_FAILURE,
    });
  }
};

export const getPaymentDetail = async (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_PAYMENT_DETAIL_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/payment/${id}`
    );
    dispatch({
      type: ACTION_TYPES.GET_PAYMENT_DETAIL_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.GET_PAYMENT_DETAIL_FAILURE,
      payload: {
        error: error || "Oops, something went wrong!",
      },
    });
  }
};

export const toggleConfirmPayByCash = (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.SET_CONFIRM_PAY_BY_CASH,
  });
};

export const toggleConfirmPayByDirectTransfer = (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.SET_CONFIRM_PAY_BY_DIRECT_TRANSFER,
  });
};

export const confirmOfflinePayment = () => {};

export default PaymentsProvider;
