import React, { useReducer, createContext } from "react";
import { deleteJSON, getJSON, postJSON, putJSON } from "utils/axios";
import _get from "lodash.get";

const initialState = {
  list: {
    isLoading: false,
    isAdding: false,
    total: 0,
    data: null,
    error: null,
  },
  detail: {
    isLoading: false,
    isUpdating: false,
    isDeleting: false,
    data: null,
    error: null,
  },
};

export const CollectionsStateContext = createContext();
export const CollectionsDispatchContext = createContext();

const ACTION_TYPES = {
  GET_COLLECTIONS_REQUEST: "GET_COLLECTIONS_REQUEST",
  GET_COLLECTIONS_SUCCESS: "GET_COLLECTIONS_SUCCESS",
  GET_COLLECTIONS_FAILURE: "GET_COLLECTIONS_FAILURE",
  CREATE_COLLECTION_REQUEST: "CREATE_COLLECTION_REQUEST",
  CREATE_COLLECTION_SUCCESS: "CREATE_COLLECTION_SUCCESS",
  CREATE_COLLECTION_FAILURE: "CREATE_COLLECTION_FAILURE",
  UPDATE_COLLECTION_REQUEST: "UPDATE_COLLECTION_REQUEST",
  UPDATE_COLLECTION_SUCCESS: "UPDATE_COLLECTION_SUCCESS",
  UPDATE_COLLECTION_FAILURE: "UPDATE_COLLECTION_FAILURE",
  DELETE_COLLECTION_REQUEST: "DELETE_COLLECTION_REQUEST",
  DELETE_COLLECTION_SUCCESS: "DELETE_COLLECTION_SUCCESS",
  DELETE_COLLECTION_FAILURE: "DELETE_COLLECTION_FAILURE",
  GET_COLLECTION_DETAIL_REQUEST: "GET_COLLECTION_DETAIL_REQUEST",
  GET_COLLECTION_DETAIL_SUCCESS: "GET_COLLECTION_DETAIL_SUCCESS",
  GET_COLLECTION_DETAIL_FAILURE: "GET_COLLECTION_DETAIL_FAILURE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_COLLECTIONS_REQUEST:
      return {
        ...state,
        list: {
          data: null,
          isLoading: true,
          error: null,
        },
      };
    case ACTION_TYPES.GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          total: action.payload.total,
          isLoading: false,
          error: null,
        },
      };
    case ACTION_TYPES.GET_COLLECTIONS_FAILURE:
      console.log(action);
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          isLoading: false,
          error: action.payload.error,
        },
      };
    case ACTION_TYPES.CREATE_COLLECTION_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isAdding: true,
        },
      };
    case ACTION_TYPES.CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isAdding: false,
        },
      };
    case ACTION_TYPES.CREATE_COLLECTION_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isAdding: false,
        },
      };
    case ACTION_TYPES.UPDATE_COLLECTION_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isEditing: true,
        },
      };
    case ACTION_TYPES.UPDATE_COLLECTION_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isEditing: false,
        },
      };
    case ACTION_TYPES.UPDATE_COLLECTION_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          isEditing: false,
        },
      };
    case ACTION_TYPES.DELETE_COLLECTION_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isDeleting: true,
        },
      };
    case ACTION_TYPES.DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isDeleting: false,
        },
      };
    case ACTION_TYPES.DELETE_COLLECTION_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          isDeleting: false,
        },
      };
    case ACTION_TYPES.GET_COLLECTION_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
        },
      };
    case ACTION_TYPES.GET_COLLECTION_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          data: action.payload.data,
        },
      };
    case ACTION_TYPES.GET_COLLECTION_DETAIL_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: null,
          error: _get(
            action,
            "payload.error",
            "Sorry, unable to fetch collections!"
          ),
          isLoading: false,
        },
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const CollectionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <CollectionsDispatchContext.Provider value={dispatch}>
      <CollectionsStateContext.Provider value={state}>
        {children}
      </CollectionsStateContext.Provider>
    </CollectionsDispatchContext.Provider>
  );
};

export const createCollection = async (dispatch, values) => {
  dispatch({
    type: ACTION_TYPES.CREATE_COLLECTION_REQUEST,
  });
  try {
    const response = await postJSON(
      `${process.env.REACT_APP_API_BASE_URL}/collection`,
      values
    );
    dispatch({
      type: ACTION_TYPES.CREATE_COLLECTION_SUCCESS,
      payload: {
        data: response.data.data,
        total: response.data.total,
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.CREATE_COLLECTION_FAILURE,
    });
  }
};

export const getAllCollections = async (dispatch, page) => {
  dispatch({
    type: ACTION_TYPES.GET_COLLECTIONS_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/collection?page=${page}`
    );
    dispatch({
      type: ACTION_TYPES.GET_COLLECTIONS_SUCCESS,
      payload: {
        data: response.data.data,
        total: response.data.total,
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.GET_COLLECTIONS_FAILURE,
      payload: {
        error: error || "Sorry, unable to fetch collections!",
      },
    });
  }
};

export const getCollectionDetail = async (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_COLLECTION_DETAIL_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/collection/${id}`
    );
    dispatch({
      type: ACTION_TYPES.GET_COLLECTION_DETAIL_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.GET_COLLECTION_DETAIL_FAILURE,
      payload: {
        error: error,
      },
    });
  }
};

export const updateCollection = async (dispatch, id, values) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_COLLECTION_REQUEST,
  });
  try {
    const response = await putJSON(
      `${process.env.REACT_APP_API_BASE_URL}/collection/${id}`,
      values
    );
    if (response) {
      dispatch({
        type: ACTION_TYPES.UPDATE_COLLECTION_SUCCESS,
      });
      return getCollectionDetail(dispatch, id);
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.UPDATE_COLLECTION_FAILURE,
    });
  }
};

export const deleteCollection = async (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.DELETE_COLLECTION_REQUEST,
  });
  try {
    const response = await deleteJSON(
      `${process.env.REACT_APP_API_BASE_URL}/collection/${id}`
    );
    if (response) {
      dispatch({
        type: ACTION_TYPES.DELETE_COLLECTION_SUCCESS,
      });
      return getCollectionDetail(dispatch, id);
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.DELETE_COLLECTION_FAILURE,
    });
  }
};

export default CollectionsProvider;
