import React, { useContext } from "react";
import classNames from "classnames";
import Header from "components/Header";
import Footer from "components/Footer";
import SideMenu from "components/SideMenu";
import { CommonStateContext } from "contexts/common";

const CommonLayout = ({ children }) => {
  const { isSideMenuOpen } = useContext(CommonStateContext);
  return (
    <div className="common-layout">
      <SideMenu />
      <div
        className={classNames("main-wrapper", {
          "side-menu-open": isSideMenuOpen,
        })}
      >
        <Header />
        <main className="main-container">{children}</main>
        <Footer />
        <div className="backdrop" />
      </div>
    </div>
  );
};

export default CommonLayout;
