import React, { useContext, useEffect } from "react";
import {
  AuthStateContext,
  AuthDispatchContext,
  checkValidity,
} from "contexts/auth";
import Loader from "components/Loader";

const GlobalWrapper = ({ children }) => {
  const { isCheckingValidity } = useContext(AuthStateContext);
  const dispatch = useContext(AuthDispatchContext);

  useEffect(() => {
    checkValidity(dispatch);
  }, [dispatch]);
  if (isCheckingValidity) {
    return (
      <div className="global-wrapper">
        <Loader />
      </div>
    );
  }
  return <div className="global-wrapper">{children}</div>;
};

export default GlobalWrapper;
