import React, { useReducer, createContext } from "react";
import { deleteJSON, getJSON, postJSON, putJSON } from "utils/axios";

const initialState = {
  list: {
    isLoading: false,
    isCreatingExpense: false,
    total: 0,
    data: null,
    error: null,
  },
  detail: {
    isLoading: false,
    isDeleting: false,
    isUpdating: false,
    data: null,
    error: null,
  },
};

export const ExpensesStateContext = createContext();
export const ExpensesDispatchContext = createContext();

const ACTION_TYPES = {
  GET_EXPENSES_REQUEST: "GET_EXPENSES_REQUEST",
  GET_EXPENSES_SUCCESS: "GET_EXPENSES_SUCCESS",
  GET_EXPENSES_FAILURE: "GET_EXPENSES_FAILURE",
  CREATE_EXPENSE_REQUEST: "CREATE_EXPENSE_REQUEST",
  CREATE_EXPENSE_SUCCESS: "CREATE_EXPENSE_SUCCESS",
  CREATE_EXPENSE_FAILURE: "CREATE_EXPENSE_FAILURE",
  UPDATE_EXPENSE_REQUEST: "UPDATE_EXPENSE_REQUEST",
  UPDATE_EXPENSE_SUCCESS: "UPDATE_EXPENSE_SUCCESS",
  UPDATE_EXPENSE_FAILURE: "UPDATE_EXPENSE_FAILURE",
  DELETE_EXPENSE_REQUEST: "DELETE_EXPENSE_REQUEST",
  DELETE_EXPENSE_SUCCESS: "DELETE_EXPENSE_SUCCESS",
  DELETE_EXPENSE_FAILURE: "DELETE_EXPENSE_FAILURE",
  GET_EXPENSE_DETAIL_REQUEST: "GET_EXPENSE_DETAIL_REQUEST",
  GET_EXPENSE_DETAIL_SUCCESS: "GET_EXPENSE_DETAIL_SUCCESS",
  GET_EXPENSE_DETAIL_FAILURE: "GET_EXPENSE_DETAIL_FAILURE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_EXPENSES_REQUEST:
      return {
        ...state,
        list: {
          data: null,
          isLoading: true,
          error: null,
        },
      };
    case ACTION_TYPES.GET_EXPENSES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          total: action.payload.total,
          isLoading: false,
          error: null,
        },
      };
    case ACTION_TYPES.GET_EXPENSES_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          isLoading: false,
          error: action.payload.error,
        },
      };
    case ACTION_TYPES.CREATE_EXPENSE_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isCreatingExpense: true,
          error: null,
        },
      };
    case ACTION_TYPES.CREATE_EXPENSE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isCreatingExpense: false,
          error: null,
        },
      };
    case ACTION_TYPES.CREATE_EXPENSE_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isCreatingExpense: false,
          error: action.payload.error,
        },
      };
    case ACTION_TYPES.GET_EXPENSE_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          error: null,
        },
      };
    case ACTION_TYPES.GET_EXPENSE_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case ACTION_TYPES.GET_EXPENSE_DETAIL_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: null,
          error: action.payload.error,
          isLoading: false,
        },
      };
    case ACTION_TYPES.UPDATE_EXPENSE_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isUpdating: true,
          error: null,
        },
      };
    case ACTION_TYPES.UPDATE_EXPENSE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isUpdating: false,
          error: null,
        },
      };
    case ACTION_TYPES.UPDATE_EXPENSE_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          error: action.payload.error,
          isUpdating: false,
        },
      };

    case ACTION_TYPES.DELETE_EXPENSE_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isDeleting: true,
          error: null,
        },
      };
    case ACTION_TYPES.DELETE_EXPENSE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isDeleting: false,
          error: null,
        },
      };
    case ACTION_TYPES.DELETE_EXPENSE_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          error: action.payload.error,
          isDeleting: false,
        },
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const ExpensesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ExpensesDispatchContext.Provider value={dispatch}>
      <ExpensesStateContext.Provider value={state}>
        {children}
      </ExpensesStateContext.Provider>
    </ExpensesDispatchContext.Provider>
  );
};

export const getAllExpenses = async (dispatch, page) => {
  dispatch({
    type: ACTION_TYPES.GET_EXPENSES_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/expense?page=${page}`
    );
    dispatch({
      type: ACTION_TYPES.GET_EXPENSES_SUCCESS,
      payload: {
        data: response.data.data,
        total: response.data.total,
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.GET_EXPENSES_FAILURE,
      payload: {
        error: error || "Sorry, unable to fetch expenses!",
      },
    });
  }
};

export const createExpense = async (dispatch, values) => {
  dispatch({
    type: ACTION_TYPES.CREATE_EXPENSE_REQUEST,
  });
  try {
    const response = await postJSON(
      `${process.env.REACT_APP_API_BASE_URL}/expense`,
      values
    );
    return dispatch({
      type: ACTION_TYPES.CREATE_EXPENSE_SUCCESS,
      payload: {
        paymentRequest: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.CREATE_EXPENSE_FAILURE,
      payload: {
        error: error || "Error creating an expense!",
      },
    });
  }
};

export const getExpenseDetail = async (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_EXPENSE_DETAIL_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/expense/${id}`
    );
    dispatch({
      type: ACTION_TYPES.GET_EXPENSE_DETAIL_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.GET_EXPENSE_DETAIL_FAILURE,
      payload: {
        error: error || "Error fetching expense details!",
      },
    });
  }
};

export const updateExpense = async (dispatch, id, values) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_EXPENSE_REQUEST,
  });
  try {
    const response = await putJSON(
      `${process.env.REACT_APP_API_BASE_URL}/expense/${id}`,
      values
    );
    return dispatch({
      type: ACTION_TYPES.UPDATE_EXPENSE_SUCCESS,
      payload: {
        paymentRequest: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.UPDATE_EXPENSE_FAILURE,
      payload: {
        error: error || "Error updating expense!",
      },
    });
  }
};

export const deleteExpense = async (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.DELETE_EXPENSE_REQUEST,
  });
  try {
    const response = await deleteJSON(
      `${process.env.REACT_APP_API_BASE_URL}/expense/${id}`
    );
    return dispatch({
      type: ACTION_TYPES.DELETE_EXPENSE_SUCCESS,
      payload: {
        paymentRequest: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.DELETE_EXPENSE_FAILURE,
      payload: {
        error: error || "Error occurred when deleting an expense!",
      },
    });
  }
};

export default ExpensesProvider;
