import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

const Dropdown = ({ children, linkContent }) => {
  const [active, setActive] = useState(false);
  const node = useRef(null);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setActive(false);
  };

  useEffect(() => {
    if (active) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [active]);
  return (
    <div className={classNames("dropdown", { show: active })} ref={node}>
      <a
        href="/#"
        className="dropdown-toggle"
        onClick={(e) => {
          e.preventDefault();
          setActive(!active);
        }}
      >
        {linkContent}
      </a>
      <div className={classNames("dropdown-menu", { show: active })}>
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
