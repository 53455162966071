import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import {
  CommonStateContext,
  CommonDispatchContext,
  toggleSideMenu,
} from "contexts/common";

const Header = () => {
  const { isSideMenuOpen } = useContext(CommonStateContext);
  const dispatch = useContext(CommonDispatchContext);
  const closeSideMenu = () => {
    if (isSideMenuOpen) {
      toggleSideMenu(dispatch);
    }
  };
  return (
    <aside
      className={classNames("side-menu-wrapper", { open: isSideMenuOpen })}
    >
      <div className="container">
        <ul>
          <li>
            <NavLink to="/" exact onClick={closeSideMenu}>
              <i className="icon-speed" />
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to="/collections" onClick={closeSideMenu}>
              <i className="icon-collections_bookmark" />
              Collections
            </NavLink>
          </li>
          <li>
            <NavLink to="/payments" onClick={closeSideMenu}>
              <i className="icon-receipt_long" />
              Payments
            </NavLink>
          </li>
          <li>
            <NavLink to="/expenses" onClick={closeSideMenu}>
              <i className="icon-insert-drive-file" />
              Expenses
            </NavLink>
          </li>
          <li>
            <NavLink to="/residents" onClick={closeSideMenu}>
              <i className="icon-badge" />
              Residents
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Header;
