import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import _get from "lodash.get";
import { AuthStateContext, AuthDispatchContext, signOut } from "contexts/auth";
import {
  CommonStateContext,
  CommonDispatchContext,
  toggleSideMenu,
} from "contexts/common";
import Dropdown from "components/core/Dropdown";

const Header = () => {
  const { isLoggedIn, user } = useContext(AuthStateContext);
  const { isSideMenuOpen } = useContext(CommonStateContext);
  const commonDispatch = useContext(CommonDispatchContext);
  const authDispatch = useContext(AuthDispatchContext);

  const handleMenu = () => {
    return toggleSideMenu(commonDispatch);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    return signOut(authDispatch);
  };

  return (
    <header className={classNames({ "side-menu-open": isSideMenuOpen })}>
      <div className="container">
        <button className="menu-button" onClick={handleMenu}>
          {isSideMenuOpen ? (
            <i className="icon-menu_open" />
          ) : (
            <i className="icon-menu" />
          )}
        </button>
        <div className="brand">
          <Link to="/">
            <img
              className="logo"
              src={process.env.PUBLIC_URL + "/residy-logo-bitone.svg"}
              alt="Residy"
            />
          </Link>
        </div>
        <div className="my-account">
          {isLoggedIn ? (
            <Dropdown
              linkContent={
                <>
                  <i className="icon-user" />
                  <span>{_get(user, "name")}</span>
                </>
              }
            >
              <a
                className="dropdown-item disabled"
                href="/#"
                onClick={(e) => e.preventDefault()}
              >
                {`Hi ${_get(user, "name")}`} <br />
                <span>({_get(user, "email")})</span>
              </a>
              <a
                className="dropdown-item"
                href="/#"
                onClick={(e) => handleLogout(e)}
              >
                Logout
              </a>
            </Dropdown>
          ) : (
            <NavLink to="/auth/login">Login</NavLink>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
