import React from "react";

const LazyLoader = () => {
  return (
    <div className="large-lazy-loader">
      <div className="spinner" />
    </div>
  );
};

export default LazyLoader;
