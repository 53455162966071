import React, { useReducer, createContext } from "react";
import jwtDecode from "jwt-decode";
import _get from "lodash.get";
import { postJSON, getJSON } from "utils/axios";
import useLocalStorage from "hooks/useLocalStorage";

const initialState = {
  isLoggingIn: false,
  isCheckingValidity: false,
  isLoggedIn: false,
  user: null,
  error: null,
};

export const AuthStateContext = createContext();
export const AuthDispatchContext = createContext();

const ACTION_TYPES = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  CHECK_VALIDITY_REQUEST: "CHECK_VALIDITY_REQUEST",
  CHECK_VALIDITY_SUCCESS: "CHECK_VALIDITY_SUCCESS",
  CHECK_VALIDITY_FAILURE: "CHECK_VALIDITY_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isLoggingIn: true,
        error: null,
      };
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
        isLoggingIn: false,
        error: null,
      };
    case ACTION_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isLoggingIn: false,
        error: action.payload.error,
      };
    case ACTION_TYPES.REGISTER_FAILURE:
      return {
        ...state,
        ...initialState,
        error: action.payload.error,
      };
    case ACTION_TYPES.REGISTER_REQUEST:
    case ACTION_TYPES.REGISTER_SUCCESS:
    case ACTION_TYPES.LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState,
      };
    case ACTION_TYPES.CHECK_VALIDITY_REQUEST:
      return {
        ...state,
        isCheckingValidity: true,
        error: "",
      };
    case ACTION_TYPES.CHECK_VALIDITY_SUCCESS:
      return {
        ...state,
        isCheckingValidity: false,
      };
    case ACTION_TYPES.CHECK_VALIDITY_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isCheckingValidity: false,
        error: action.payload.error,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const checkValidity = async (dispatch) => {
  try {
    dispatch({
      type: ACTION_TYPES.CHECK_VALIDITY_REQUEST,
    });
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = await jwtDecode(token);
      if (decoded && Date.now() <= decoded.exp * 1000) {
        return dispatch({
          type: ACTION_TYPES.CHECK_VALIDITY_SUCCESS,
        });
      } else {
        dispatch({
          type: ACTION_TYPES.CHECK_VALIDITY_FAILURE,
          payload: {
            error: "Session expired, please login again!",
          },
        });
      }
    } else {
      dispatch({
        type: ACTION_TYPES.CHECK_VALIDITY_FAILURE,
        payload: {
          error: "",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: ACTION_TYPES.CHECK_VALIDITY_FAILURE,
      payload: {
        error: `${error?.message}, please retry login!`,
      },
    });
  }
};

export const signIn = async (dispatch, userData) => {
  dispatch({
    type: ACTION_TYPES.LOGIN_REQUEST,
  });
  try {
    const response = await postJSON(
      `${process.env.REACT_APP_API_BASE_URL}/user/login`,
      userData
    );
    const token = response.data["auth-token"];
    localStorage.setItem("token", token);
    return dispatch({
      type: ACTION_TYPES.LOGIN_SUCCESS,
      payload: {
        user: jwtDecode(token),
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.LOGIN_FAILURE,
      payload: {
        error: error,
      },
    });
  }
};

export const register = async (dispatch, userData) => {
  dispatch({
    type: ACTION_TYPES.REGISTER_REQUEST,
  });
  try {
    const response = await postJSON(
      `${process.env.REACT_APP_API_BASE_URL}/user/register`,
      userData
    );
    if (response.data === "Registered successfully!") {
      return dispatch({
        type: ACTION_TYPES.REGISTER_SUCCESS,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.REGISTER_FAILURE,
      payload: {
        error: error,
      },
    });
  }
};

export const signOut = (dispatch) => {
  localStorage.clear();
  return dispatch({
    type: "LOGOUT_SUCCESS",
  });
};

export const wakeUp = async () => {
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/user/wakeUp`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const AuthProvider = ({ children }) => {
  const [persistedUser] = useLocalStorage("user", {});
  const persistedUserState = {
    user: persistedUser,
    isLoggedIn: _get(persistedUser, "name", "").length > 0,
  };
  const [state, dispatch] = useReducer(reducer, persistedUserState);
  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export default AuthProvider;
