import React, { useReducer, createContext } from "react";

const initialState = {
  isSideMenuOpen: false,
};

const ACTION_TYPES = {
  TOGGLE_SIDE_MENU: "TOGGLE_SIDE_MENU",
};

export const CommonStateContext = createContext();
export const CommonDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_SIDE_MENU:
      return {
        ...state,
        isSideMenuOpen: !state.isSideMenuOpen,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const CommonProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <CommonDispatchContext.Provider value={dispatch}>
      <CommonStateContext.Provider value={state}>
        {children}
      </CommonStateContext.Provider>
    </CommonDispatchContext.Provider>
  );
};

export const toggleSideMenu = (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.TOGGLE_SIDE_MENU,
  });
};

export default CommonProvider;
