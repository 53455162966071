import React, { useReducer, createContext } from "react";
import { getJSON } from "utils/axios";
import _get from "lodash.get";

const initialState = {
  stats: {
    expenses: {
      data: null,
      isLoading: false,
      error: null,
    },
    payments: {
      data: null,
      isLoading: false,
      error: null,
    },
    overdues: {
      data: null,
      isLoading: false,
      error: null,
    },
  },
  upcomingPayments: {
    data: null,
    isLoading: false,
    error: null,
  },
};

export const DashboardStateContext = createContext();
export const DashboardDispatchContext = createContext();

const ACTION_TYPES = {
  GET_EXPENSE_STATS_REQUEST: "GET_EXPENSE_STATS_REQUEST",
  GET_EXPENSE_STATS_SUCCESS: "GET_EXPENSE_STATS_SUCCESS",
  GET_EXPENSE_STATS_FAILURE: "GET_EXPENSE_STATS_FAILURE",
  GET_PAYMENT_STATS_REQUEST: "GET_PAYMENT_STATS_REQUEST",
  GET_PAYMENT_STATS_SUCCESS: "GET_PAYMENT_STATS_SUCCESS",
  GET_PAYMENT_STATS_FAILURE: "GET_PAYMENT_STATS_FAILURE",
  GET_OVERDUE_PAYMENT_STATS_REQUEST: "GET_OVERDUE_PAYMENT_STATS_REQUEST",
  GET_OVERDUE_PAYMENT_STATS_SUCCESS: "GET_OVERDUE_PAYMENT_STATS_SUCCESS",
  GET_OVERDUE_PAYMENT_STATS_FAILURE: "GET_OVERDUE_PAYMENT_STATS_FAILURE",
  GET_UPCOMING_PAYMENTS_REQUEST: "GET_UPCOMING_PAYMENTS_REQUEST",
  GET_UPCOMING_PAYMENTS_SUCCESS: "GET_UPCOMING_PAYMENTS_SUCCESS",
  GET_UPCOMING_PAYMENTS_FAILURE: "GET_UPCOMING_PAYMENTS_FAILURE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_EXPENSE_STATS_REQUEST:
      return {
        ...state,
        stats: {
          ...state.stats,
          expenses: {
            ...state.stats.expenses,
            data: null,
            error: null,
            isLoading: true,
          },
        },
      };
    case ACTION_TYPES.GET_EXPENSE_STATS_SUCCESS:
      return {
        ...state,
        stats: {
          ...state.stats,
          expenses: {
            ...state.stats.expenses,
            data: action.payload.data,
            isLoading: false,
          },
        },
      };
    case ACTION_TYPES.GET_EXPENSE_STATS_FAILURE:
      return {
        ...state,
        stats: {
          ...state.stats,
          expenses: {
            ...state.stats.expenses,
            data: null,
            error: action.payload.error,
            isLoading: false,
          },
        },
      };
    case ACTION_TYPES.GET_PAYMENT_STATS_REQUEST:
      return {
        ...state,
        stats: {
          ...state.stats,
          payments: {
            ...state.stats.payments,
            data: null,
            error: null,
            isLoading: true,
          },
        },
      };
    case ACTION_TYPES.GET_PAYMENT_STATS_SUCCESS:
      return {
        ...state,
        stats: {
          ...state.stats,
          payments: {
            ...state.stats.payments,
            data: action.payload.data,
            isLoading: false,
          },
        },
      };
    case ACTION_TYPES.GET_PAYMENT_STATS_FAILURE:
      return {
        ...state,
        stats: {
          ...state.stats,
          payments: {
            ...state.stats.payments,
            data: null,
            error: action.payload.error,
            isLoading: false,
          },
        },
      };
    case ACTION_TYPES.GET_OVERDUE_PAYMENT_STATS_REQUEST:
      return {
        ...state,
        stats: {
          ...state.stats,
          overdues: {
            ...state.stats.overdues,
            data: null,
            error: null,
            isLoading: true,
          },
        },
      };
    case ACTION_TYPES.GET_OVERDUE_PAYMENT_STATS_SUCCESS:
      console.log("action", action);
      return {
        ...state,
        stats: {
          ...state.stats,
          overdues: {
            ...state.stats.overdues,
            data: action.payload.data,
            isLoading: false,
          },
        },
      };
    case ACTION_TYPES.GET_OVERDUE_PAYMENT_STATS_FAILURE:
      return {
        ...state,
        stats: {
          ...state.stats,
          overdues: {
            ...state.stats.overdues,
            data: null,
            error: action.payload.error,
            isLoading: false,
          },
        },
      };
    case ACTION_TYPES.GET_UPCOMING_PAYMENTS_REQUEST:
      return {
        ...state,
        upcomingPayments: {
          ...state.upcomingPayments,
          data: null,
          error: null,
          isLoading: true,
        },
      };
    case ACTION_TYPES.GET_UPCOMING_PAYMENTS_SUCCESS:
      return {
        ...state,
        upcomingPayments: {
          ...state.upcomingPayments,
          data: action.payload.data,
          isLoading: false,
        },
      };
    case ACTION_TYPES.GET_UPCOMING_PAYMENTS_FAILURE:
      return {
        ...state,
        upcomingPayments: {
          ...state.upcomingPayments,
          data: null,
          isLoading: false,
          error: action.payload.error,
        },
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const DashboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DashboardDispatchContext.Provider value={dispatch}>
      <DashboardStateContext.Provider value={state}>
        {children}
      </DashboardStateContext.Provider>
    </DashboardDispatchContext.Provider>
  );
};

export const getExpenseStats = async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_EXPENSE_STATS_REQUEST,
  });
  try {
    const yearResponse = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/expense/dashboard-v2?period=year`
    );
    const historyResponse = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/expense/usage-history?period=year`
    );
    console.log("Usage History WIP", historyResponse?.data);
    dispatch({
      type: ACTION_TYPES.GET_EXPENSE_STATS_SUCCESS,
      payload: {
        data: {
          year: _get(yearResponse, "data[0].totalAmount", 0),
        },
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.GET_EXPENSE_STATS_FAILURE,
      payload: {
        error: error || "Oops!",
      },
    });
  }
};

export const getPaymentStats = async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_PAYMENT_STATS_REQUEST,
  });
  try {
    const yearResponse = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/payment/dashboard-v2?period=year`
    );
    dispatch({
      type: ACTION_TYPES.GET_PAYMENT_STATS_SUCCESS,
      payload: {
        data: {
          year: _get(yearResponse, "data[0].totalAmount", 0),
        },
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.GET_PAYMENT_STATS_FAILURE,
      payload: {
        error: error || "Oops!",
      },
    });
  }
};

export const getOverduePaymentStats = async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_OVERDUE_PAYMENT_STATS_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/payment/dashboard-v2-overdue`
    );
    dispatch({
      type: ACTION_TYPES.GET_OVERDUE_PAYMENT_STATS_SUCCESS,
      payload: {
        data: {
          overdue: _get(response, "data[0].totalAmount", 0),
        },
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.GET_OVERDUE_PAYMENT_STATS_FAILURE,
      payload: {
        error: error || "Oops!",
      },
    });
  }
};

export const getUpcomingPayments = async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_UPCOMING_PAYMENTS_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/payment?status=OPEN`
    );
    const data = _get(response, "data", []);
    dispatch({
      type: ACTION_TYPES.GET_UPCOMING_PAYMENTS_SUCCESS,
      payload: {
        data: data,
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.GET_UPCOMING_PAYMENTS_FAILURE,
      payload: {
        error: error || "Oops!",
      },
    });
  }
};

export default DashboardProvider;
