import React from "react";

const Loader = () => {
  return (
    <div className="loading-message">
      <img src={process.env.PUBLIC_URL + "/loading.gif"} alt="Loading" />
    </div>
  );
};

export default Loader;
