import axios from "axios";
import _get from "lodash.get";

export const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
  timeout: 15000,
});

// request interceptor for adding token
API.interceptors.request.use((config) => {
  config.headers["auth-token"] = localStorage.getItem("token");
  return config;
});

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { status } = error.response;
    if (status === 403 || status === 401) {
      localStorage.clear();
      window.location.replace("/auth/login");
    }
    return Promise.reject(error);
  }
);

export function getJSON(url) {
  return API.get(url)
    .then((res) => res)
    .catch((error) => {
      throw _get(error, "response.data");
    });
}

export function postJSON(url, values) {
  return API.post(url, values)
    .then((res) => res)
    .catch((error) => {
      throw _get(error, "response.data");
    });
}

export function putJSON(url, values) {
  return API.put(url, values)
    .then((res) => res)
    .catch((error) => {
      throw _get(error, "response.data");
    });
}

export function deleteJSON(url) {
  return API.delete(url)
    .then((res) => res)
    .catch((error) => {
      throw _get(error, "response.data");
    });
}
